import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VChip,_vm._g({attrs:{"color":_vm.completedCount ? 'success' : 'gray',"small":""}},on),[(_vm.completedCount)?_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-check")]):_vm._e(),(_vm.completedCount === _vm.len)?_c('span',[_vm._v("All ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.completedCount)+" "),(_vm.completedCount !== _vm.len)?_c('span',[_vm._v(" of "+_vm._s(_vm.len)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.pluralize("Sheet", _vm.len))+" Completed ")],1)]}}])},[_c(VCard,{staticClass:"pa-2"},[_c('table',[_c('thead',[_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Changes")])]),_c('tbody',_vm._l((_vm.sheets),function(ref){
            var id = ref.id;
            var model = ref.model;
            var chipProps = ref.chipProps;
            var chipIcon = ref.chipIcon;
            var chipText = ref.chipText;
            var auditCounts = ref.auditCounts;
return _c('tr',{key:id},[_c('td',[_vm._v(_vm._s(model || "Sheet not mapable"))]),_c('td',{staticStyle:{"height":"26px"}},[_c(VChip,_vm._b({staticClass:"mx-2",attrs:{"small":""}},'v-chip',chipProps || {},false),[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(_vm._s(chipIcon))]),_vm._v(" "+_vm._s(chipText))],1)],1),_c('td',{staticClass:"text-no-wrap"},[(_vm.isEmpty(auditCounts))?_c('span',{staticClass:"text-caption"},[_vm._v("none")]):_vm._e(),_vm._l((auditCounts),function(v,key){return _c('action-chip',{key:(id + "-" + key + "s"),staticClass:"pl-2",attrs:{"action":key,"count":v,"x-small":""}})})],2)])}),0)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }