<template>
  <cp-loading :loading="loading">
    <cp-h1>
      {{ item.full_name }}

      <template #subtitle>
        <model-details-dates :item="item" />
      </template>

      <template #right>
        <v-btn
          @click="update"
          :disabled="!touched.length"
          :loading="loading"
          color="primary"
          text
        >
          <v-icon left>mdi-content-save</v-icon>
          Update
        </v-btn>

        <v-btn
          v-if="item.status_id === 1"
          @click="sendWelcomeEmail"
          color="primary"
          class="ml-4"
          text
        >
          <v-icon left>mdi-email</v-icon>
          Send Welcome Email
        </v-btn>

        <v-divider vertical class="mx-4" />
        <v-btn
          v-if="hasArchiveBA && item.status_id === 1"
          @click="archive"
          color="error"
          text
        >
          <v-icon left>mdi-archive</v-icon>
          Archive
        </v-btn>
        <v-btn
          v-else-if="hasRestoreBA && item.status_id === 2"
          @click="restore"
          color="green"
        >
          Restore
        </v-btn>
      </template>
    </cp-h1>
    <model-tabs :modelModule="employee" />

    <cp-toolbar-module-modal
      key="archive-employee-dialog"
      :modalConf="archiveAction.modalConf"
      @closed=""
    >
      <template #title> Archive Employee? </template>

      <p v-if="archiveAction.explanation">
        {{ archiveAction.explanation }}
      </p>

      <div
        v-if="!archiveAction.hideChips"
        class="d-flex justify-center flex-wrap"
        style="gap: 10px"
      >
        <template v-for="item in selected">
          <v-chip
            :key="item.id"
            :set="(name = `${item.first_name} ${item.last_name}`)"
          >
            {{ name }}
          </v-chip>
        </template>
      </div>

      <component
        v-if="archiveAction.modalComponent"
        :is="archiveAction.modalComponent"
        :selected="selected"
        :action="archiveAction"
      />

      <div v-else class="d-flex justify-end mt-6">
        <div v-if="archiveAction.success" class="d-flex align-center">
          <CpSuccessIcon class="mr-4" xLarge />
          {{ successMessage(archiveAction) }}
        </div>
        <template v-else>
          <v-btn @click="archiveAction.modalConf.close" class="mr-4"
            >Cancel</v-btn
          >
          <v-btn
            @click="archiveAction.confirm"
            color="primary"
            :loading="archiveAction.loading"
          >
            {{ buttonText(archiveAction) }}
          </v-btn>
        </template>
      </div>
    </cp-toolbar-module-modal>
  </cp-loading>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import pluralize from "pluralize";

import { toMDY } from "@cp/utils/dateUtils";
import { get } from "@cp/utils/objectUtils";
import { findByObj } from "@cp/utils/arrayUtils";
import { onCpEvent } from "@cp/mixins";

import { employee } from "@/store/modules/employee";

export default {
  mixins: [
    onCpEvent("bulkActionSuccess", function() {
      this.refresh();
    }),
  ],
  data() {
    return {
      employee,
    };
  },
  computed: {
    ...mapState(employee.mp, ["item"]),
    ...mapState(employee.mp, {
      touched: "itemTouched",
      loading: "itemLoading",
      submitting: "itemSubmitting",
    }),
    ...mapGetters(employee.mp, ["tabs", "itemsBulkActions"]),
    hasArchiveBA() {
      return findByObj(employee.table.bulkActions, { name: "restore" });
    },
    hasRestoreBA() {
      return findByObj(employee.table.bulkActions, { name: "archive" });
    },
    selected() {
      return [this.item];
    },

    // coppied and adapted from @/components/bulkActions/BulkActionModals.vue:computed.actionModals
    archiveAction() {
      const $vm = this;
      const { subModule, ...ba } = employee.table.bulkActions.find(
        x => x.name === "archive"
      );
      const labelParts = [ba.name.replace("_")];
      if (ba.article) labelParts.push(ba.article);
      labelParts.push(
        pluralize(employee.table.noun, this.selected.length, true)
      );
      return {
        ...ba,
        confirm: () => {
          let data;
          if (ba.dataFn) data = ba.dataFn($vm.selected);
          $vm.$store.dispatch(subModule.p.a.confirm, data);
        },
        loading: get($vm.$store.state, subModule.p.s.submitting),
        success: get($vm.$store.state, subModule.p.s.success),
        subModule,
        modalConf: {
          props: {
            value: get(this.$store.state, subModule.p.s.modalState),
            width: subModule.width || 500,
          },
          close: args => $vm.$store.dispatch(subModule.p.a.modalClose, args),
          open: args => $vm.$store.dispatch(subModule.p.a.modalOpen, args),
          opened: subModule.keys.modalOpenedEvent,
          closed: subModule.keys.modalClosedEvent,
          listeners: {
            input(value) {
              if (value) {
                open();
              } else {
                close();
              }
            },
          },
        },
      };
    },
  },
  methods: {
    toMDY,
    ...mapMutations(employee.mp, ["resetItem"]),
    ...mapActions(employee.mp, ["fetchItem", "update", "restore"]),
    ...mapMutations(employee.mp, ["resetItem"]),
    sendWelcomeEmail() {
      const ap = employee.table.subModuleRefs.bulkSendWelcomeEmails.p.a.confirm;
      this.$store.dispatch(ap, { ids: [this.item.id] });
    },
    archive() {
      this.$store.dispatch(
        employee.table.subModuleRefs.bulkArchive.p.a.modalOpen,
        [this.item]
      );
    },
    refresh() {
      const { id } = this.$route.params;
      if (id) {
        this.resetItem();
        this.fetchItem({ id });
      }
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
