import pluralize from "pluralize";
import cloneDeep from "clone-deep";

import { eventbus } from "@cp/lib";
import { Mixin, ItemsTable, ItemForm } from "@cp/store/mixins";
import { BulkActionDialog } from "./BulkActionDialog";
import { Audit } from "./Audit";

import { capitalize, snakeToCamelCase } from "@cp/utils/stringUtils";
import { deepMerge, pick } from "@cp/utils/objectUtils";
import { toggle } from "@cp/utils/arrayUtils";
import { flattenPath } from "@cp/utils/pathUtils";
import { Client } from "@cp/lib";
import { wait } from "@cp/utils/promiseUtils";

const BASE_URL = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`;
const downloadClient = new Client({
  baseUrl: `${BASE_URL}/dashboard/export/export.xlsx`,
});

const DETAIL_TABS = {
  general: {},
  settings: { roleRequired: "swiftbunny-admin" },
  history: {},
  "related-items": {},
  "review-links": {},
  office: {},
  property: {},
  locations: {},
  regions: {},
};

class ModelForm extends ItemForm {
  //TODO - overwrite error getters to support form modes
  constructor({ fieldOrder = [], noun, ...config }) {
    super(config);
    this.instantiate({ fieldOrder, noun });
    this.add({
      keys: ["fieldOrder", "create", "update", "archive", "restore"],
      state: { mode: "" }, // import || transform || edit || create,
      getters: {
        fieldOrder() {
          return this.fieldOrder;
        },
      },
      actions: {
        [this.keys.resetFields]: this.resetFields,
        create({ dispatch }) {
          return dispatch(this.keys.submit, { url: "", method: "POST" });
        },
        async update({ state, dispatch, commit }) {
          const id = state.item.id;
          try {
            const { errors } = await dispatch(this.keys.submit, {
              id,
              method: "PUT",
            });

            if (errors == "Form Invalid") {
              eventbus.$emit("modelUpdateFailed");
              return;
            }

            if (!state.itemSuccess) return;

            eventbus.$emit("snackAlert", {
              message: `${capitalize(this.noun)} Updated!`,
              color: "success",
              dismissable: true,
            });
            await dispatch("fetchItem", { id });
            commit("itemResetFields");
            await wait(1500);
            state[this.keys.success] = false;
            state[this.keys.submitAttempted] = false;
          } catch (errors) {
            console.log(errors);
            eventbus.$emit("modelUpdateFailed");
          }
        },
        async archive({ state, dispatch }) {
          const id = state.item.id;
          const aPath = flattenPath(this.mp, "bulkArchive.confirm");
          await dispatch(aPath.join("/"), { ids: [id] }, { root: true });
          dispatch("fetchItem", { id });
        },
        async restore({ state, dispatch }) {
          const id = state.item.id;
          const aPath = flattenPath(this.mp, "bulkRestore.confirm");
          await dispatch(aPath.join("/"), { ids: [id] }, { root: true });
          dispatch("fetchItem", { id });
        },
      },
    });
  }

  resetFields(state, fieldOrFields) {
    if (!fieldOrFields) {
      const formData = pick(state[this.keys.stateKey], this.fieldKeys);
      Object.assign(state[this.keys.formData], formData);
      for (const key of this.fieldKeys) {
        toggle(state[this.keys.touched], key, false);
      }
      return;
    }
    const fields = Array.isArray(fieldOrFields)
      ? fieldOrFields
      : [fieldOrFields];
    const pickedInitialValues = cloneDeep(pick(this.initialFormValue, fields));
    Object.assign(state[this.keys.formData], pickedInitialValues);
    for (const key of fields) {
      toggle(state[this.keys.touched], key, false);
    }
  }
}

class ModelTable extends ItemsTable {
  constructor({
    useGlobalBulkActions = true,
    bulkActions: bulkActionsArg = [],
    exportOptions: exportOptionsArg = {},
    urlKey,
    ...config
  }) {
    const globalBulkActions = [
      {
        name: "restore",
        showIf: selected => selected.some(({ status_id }) => status_id === 2),
        successMessage: ({ ids }) =>
          `${pluralize(config.noun, ids.length, true)} restored!`,
      },
      {
        name: "archive",
        showIf: selected => selected.some(({ status_id }) => status_id === 1),
        successMessage: ({ ids }) =>
          `${pluralize(config.noun, ids.length, true)} archived!`,
      },
    ];
    const bulkActions = [];
    if (useGlobalBulkActions) bulkActions.push(...globalBulkActions);
    if (bulkActionsArg) bulkActions.push(...bulkActionsArg);
    const exportOptions = deepMerge(
      {},
      {
        downloadXlsx() {
          // May need to pass a special config variable here instead:
          return downloadClient.download("", null, { [urlKey]: true });
        },
      },
      exportOptionsArg
    );
    super({ ...config, bulkActions, exportOptions });

    const modules = {};
    for (const bulkAction of bulkActions) {
      const {
        url,
        name,
        moduleKey = `bulk${capitalize(snakeToCamelCase(name))}`,
        ...BAcfg
      } = bulkAction;

      if (!name) throw `${this.mp} Model.table.bulkAction requires name!`;

      const subModule = new BulkActionDialog({
        parent: this,
        module: moduleKey,
        baseUrl: config.baseUrl,
        url: url || config.baseUrl + `/${name}`,
        noun: config.noun || this.noun,
        ...BAcfg,
      });
      bulkAction.subModule = subModule;
      modules[moduleKey] = subModule;
    }

    this.add({ modules });
  }
}

export class Model extends Mixin {
  constructor({
    module,
    urlKey = `${module}s`,
    noun = module,
    url = "/:id",
    search,
    audit: auditArg = {},
    table: tableArg = {},
    form: formArg = {},
    detailTabs = ["general", "history"],
    ...config
  }) {
    super({ module });
    const nounTitle = noun
      .split(" ")
      .map(capitalize)
      .join(" ");
    this.instantiate({ noun, nounTitle, urlKey, url, search });

    const baseUrl = `${BASE_URL}/${urlKey}`;

    const audit = new Audit({
      module,
      baseUrl,
      ...config,
      ...auditArg,
      url: `${url}/audit`,
    });
    this.instantiate({ audit });
    this.add(audit);
    if (tableArg) {
      const table = new ModelTable({
        module,
        baseUrl,
        noun,
        urlKey,
        ...config,
        ...tableArg,
      });
      this.instantiate({ table });
      this.add(table);
    }
    if (formArg) {
      const form = new ModelForm({
        module,
        noun,
        baseUrl,
        url,
        urlTemplate: true,
        ...config,
        ...formArg,
      });
      this.instantiate({ form });
      this.add(form);
    }
    this.add({
      keys: ["detailTabs"],
      getters: {
        detailTabs(state, getters, rootState, rootGetters) {
          const userIs = rootGetters["auth/userIs"];
          const item = state.item;
          const modelTabs =
            typeof detailTabs === "function" ? detailTabs(item) : detailTabs;

          if (!Array.isArray(modelTabs))
            throw "Model({modelTabs}) needs to resolve to an array.";

          const filteredByRole = modelTabs.filter(id => {
            if (DETAIL_TABS[id].roleRequired)
              return userIs(DETAIL_TABS[id].roleRequired);
            return true;
          });

          // swiftbunny users get all UI regardless of client services
          if (userIs("swiftbunny-admin")) return filteredByRole;

          // non-swiftbunny users only see tabs for the services they pay for
          return filteredByRole.filter(id => {
            if (DETAIL_TABS[id].serviceRequired) {
              const args = Array.isArray(DETAIL_TABS[id].serviceRequired)
                ? DETAIL_TABS[id].serviceRequired
                : [DETAIL_TABS[id].serviceRequired];
              return rootGetters["auth/clientHasService"](...args);
            }
            return true;
          });
        },
      },
    });
  }
}
