<template>
  <div>
    <v-menu open-on-hover offset-y>
      <template #activator="{on}">
        <v-chip :color="completedCount ? 'success' : 'gray'" small v-on="on">
          <v-icon v-if="completedCount" left small>mdi-check</v-icon>
          <span v-if="completedCount === len">All&nbsp;</span>
          {{ completedCount }}
          <span v-if="completedCount !== len">&nbsp;of {{ len }}&nbsp;</span>
          {{ pluralize("Sheet", len) }} Completed
        </v-chip>
      </template>
      <v-card class="pa-2">
        <table>
          <thead>
            <th>Type</th>
            <th>Status</th>
            <th>Changes</th>
          </thead>
          <tbody>
            <tr
              v-for="{
                id,
                model,
                chipProps,
                chipIcon,
                chipText,
                auditCounts,
              } of sheets"
              :key="id"
            >
              <td>{{ model || "Sheet not mapable" }}</td>
              <td style="height:26px">
                <v-chip v-bind="chipProps || {}" small class="mx-2">
                  <v-icon left small>{{ chipIcon }}</v-icon>
                  {{ chipText }}</v-chip
                >
              </td>
              <td class="text-no-wrap">
                <span v-if="isEmpty(auditCounts)" class="text-caption"
                  >none</span
                >
                <action-chip
                  v-for="(v, key) in auditCounts"
                  :key="`${id}-${key}s`"
                  :action="key"
                  :count="v"
                  class="pl-2"
                  x-small
                />
              </td>
            </tr>
          </tbody>
        </table>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { snakeToTitleCase } from "carrot-patch-v2/src/utils/stringUtils";
import pluralize from "pluralize";

import { isEmpty } from "@cp/utils/objectUtils";

const statusIcons = {
  processing: "mdi-timer-sand",
  "un-mapped": "mdi-map-legend",
  mapped: "mdi-map-check",
  "pre-processed": "mdi-file-check-outline",
  completed: "mdi-check",
  cancelled: "mdi-cancel",
};

export default {
  props: ["item", "header", "index", "isMobile", "value"],
  computed: {
    completedCount() {
      return this.item.sheets.filter(x => x.step === "completed").length;
    },
    len() {
      return this.item.sheets.length;
    },
    sheets() {
      return this.item.sheets.map(sheet => {
        const model = snakeToTitleCase(sheet.import_model);
        const chipText = snakeToTitleCase(sheet.step);
        const chipProps =
          sheet.step === "completed" ? { color: "success" } : {};
        const chipIcon = statusIcons[sheet.step];
        const auditCounts = sheet.audit_summary;
        return {
          id: sheet.id,
          model,
          chipProps,
          chipIcon,
          chipText,
          auditCounts,
        };
      });
    },
  },
  methods: { pluralize, isEmpty },
};
</script>
