<template>
  <v-chip
    v-if="(chip && count === null) || count"
    :color="chip.color"
    v-bind="$attrs"
  >
    <v-icon v-bind="{ ...iconProps, ...$attrs }" left>{{ chip.icon }}</v-icon>
    <span v-if="count">
      {{ pluralize(chip.title, count, true) }}
    </span>
    <span v-else>
      {{ chip.title }}
    </span>
  </v-chip>
</template>

<script>
import pluralize from "pluralize";
import { actionsObj } from "@/store/modules/pendingChange";
import { pick } from "carrot-patch-v2/src/utils/objectUtils";

export default {
  props: {
    action: { type: String, required: true },
    count: { default: null },
  },
  computed: {
    chip() {
      return actionsObj[this.action];
    },
    iconProps() {
      return pick(this.$attrs, ["x-small", "small", "large", "x-large"]);
    },
  },
  methods: { pluralize },
};
</script>
