<template>
  <div>
    {{ selected.length }}
    {{ file.instance.load_type | pluralize(selected.length) | capitalize }}
    Selected
    <template v-if="selected.length > 0">
      <v-btn
        class="ml-4 mr-4"
        color="error"
        @click="
          selected.forEach(item => {
            item.approved = 0;
            selected = [];
          })
        "
      >
        <v-icon class="mr-2">mdi-close</v-icon> Reject Selected
      </v-btn>
      <v-btn
        color="success"
        @click="
          selected.forEach(item => {
            item.approved = 1;
            selected = [];
          })
        "
      >
        <v-icon class="mr-2">mdi-check</v-icon> Accept Selected
      </v-btn>
    </template>
    <v-data-table
      v-model="selected"
      :headers="formattedColumns"
      :items="file.reviewData[topic]"
      show-select
      fixed-header
      height="50vh"
      style="min-height: 250px"
      @click:row="viewPendingChange"
    >
    </v-data-table>
    <v-divider class="pb-4" />
    <v-row class="pb-2">
      <v-col align="right"> </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import pluralize from "pluralize";

import { pendingChange } from "@/store/modules/pendingChange";

export default {
  mixins: [pendingChange.modalMixin],
  props: {
    topic: { type: String, default: "adds" },
  },

  data() {
    return {
      selected: [],
    };
  },

  computed: {
    ...mapState({
      file: state => state.file,
    }),
    ...mapGetters("file", ["reviewDataPresent"]),

    submitDisabled() {
      return this.approvals.length <= 0 && this.rejections.length <= 0;
    },

    submitLoading() {
      return this.file.processing || this.file.saving;
    },

    approvals() {
      return this.file.reviewData[this.topic].filter(x => x.approved == 1);
    },

    rejections() {
      return this.file.reviewData[this.topic].filter(x => x.approved == 0);
    },

    formattedColumns() {
      let columns = this.file.reviewData.columns.map(c => ({
        value: Object.keys(c)[0],
        text: Object.values(c)[0],
      }));

      columns.push({
        text: "Reject | Accept",
        value: "actions",
        sortable: false,
        width: "150px",
        align: "center",
      });

      return columns;
    },

    actOnAllButtonText() {
      const n = this.reviewDataPresent;
      const count = n > 1 ? `All ${n}` : n;
      const noun = pluralize("Record", n);
      return `${count} ${noun}`;
    },
  },

  methods: {
    async submitAll() {
      let rejectIds = this.rejections.map(x => x.id);
      let approveIds = this.approvals.map(x => x.id);

      this.file.saving = true;

      const promises = [];

      if (rejectIds.length) {
        promises.push(
          this.$store.dispatch("file/rejectPendingChange", {
            ids: rejectIds,
            importId: this.file.instance.id,
          })
        );
      }

      if (approveIds.length) {
        promises.push(
          this.$store.dispatch("file/approvePendingChange", {
            ids: approveIds,
            importId: this.file.instance.id,
          })
        );
      }

      if (promises.length) await Promise.all(promises);

      this.file.saving = false;
    },

    viewPendingChange(item) {
      const id = item.id;
      this.openModal({ id });
    },
  },
};
</script>

<style lang="scss">
.v-input--selection-controls__input:hover
  .v-input--selection-controls__ripple:before {
  transform: scale(1.4);
  border-radius: 0;
}

.mar-toggle-active .v-btn__content .v-icon {
  color: $white !important;
}
</style>
