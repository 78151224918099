<template>
  <div>
    <span v-for="(date, i) in dates" :key="i" :class="date.class || ''">
      {{ date.label }} {{ date.value }}
    </span>
  </div>
</template>

<script>
import { toMDY, toMDYTime } from "@cp/utils/dateUtils";

export default {
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    dates() {
      let dates = [];
      if (this.item.created_at)
        dates.push({ label: "Created", value: toMDY(this.item.created_at) });

      if (this.item.archived_at) {
        dates.push({
          label: "Archived",
          value: toMDY(this.item.archived_at),
          class: "red--text",
        });
      } else if (
        this.item.updated_at &&
        this.item.updated_at !== this.item.created_at
      ) {
        dates.push({
          label: "Updated",
          value: toMDYTime(this.item.updated_at),
          class: "orange--text text--darken-3",
        });
      }
      return dates;
    },
  },
};
</script>

<style lang="scss" scoped>
span + span {
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid $gray-2;
}
</style>
