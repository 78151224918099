<template>
  <div>
    <div
      v-for="(mentor, i) in mentors"
      :key="i"
      class="text-no-wrap MentorCell__item"
    >
      {{ manager }}
    </div>
  </div>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

export default {
  name: "MentorsCell",
  props: ["item"],
  computed: {
    mentors() {
      return get(this.item, "mentors", []).map(x => x.label);
    },
  },
};
</script>

<style lang="scss">
.ManagerCell__item {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
