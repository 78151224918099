<template>
  <div class="cp-date-picker">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
      :disabled="disabled"
    >
      <template #activator="{ on }">
        <cp-input
          :value="displayedDate"
          :label="label"
          :disabled="disabled"
          append-icon="event"
          readonly
          v-bind="$attrs"
          v-on="on"
          @blur="$emit('blur', $event)"
          @click:append="menu = true"
        />
      </template>
      <v-date-picker :value="date" @input="input" v-bind="datePickerProps" />
    </v-menu>
  </div>
</template>

<script>
import { parse, MDY, YMD } from "@cp/utils/dateUtils";
import { wait } from "@cp/utils/promiseUtils";

export default {
  props: {
    label: { type: String, default: "Select Date" },
    value: { type: [String, Number, Date], default: null },
    displayFormat: { type: String, default: MDY },
    dataFormat: { type: String, default: YMD },
    defaultValue: { type: String, default: "" },
    persistent: { type: Boolean, default: false },
    datePickerProps: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      date: this.value || this.defaultValue || "",
      menu: false,
    };
  },

  computed: {
    displayedDate() {
      if (!this.date) return "";
      return parse(this.date, this.dataFormat).format(this.displayFormat);
    },
  },

  watch: {
    value() {
      this.date = this.value || this.defaultValue || "";
    },
  },

  methods: {
    async input(date) {
      this.date = parse(date, this.dataFormat).format(this.dataFormat);
      this.$emit("input", this.date);

      if (!this.persistent) {
        await wait(300);
        this.menu = false;
      }
    },
  },
};
</script>
