<template>
  <div>
    <cp-h1>
      <template #subtitle>
        <span v-if="selected.length">
          Selected: {{ selected.length }}/{{ meta.pagination.records }}
        </span>
        <span v-else>Total: {{ meta.pagination.records }}</span>
      </template>

      <template #rightOfTitle>
        <v-btn :to="{ name: 'CurrentImport' }" color="primary" small>
          <v-icon left>mdi-plus</v-icon>
          Start New Import
        </v-btn>
      </template>
    </cp-h1>

    <v-card>
      <v-data-table
        v-bind="tableProps"
        v-on="tableListeners"
        class="clickable-rows"
        @click:row="
          ({ id }) => $router.push({ name: 'importSummary', params: { id } })
        "
      >
        <template #item.created_at="{value}">
          {{ toMDYTime(value) }}
        </template>

        <template #item.parent_audit_counts="{item, value}">
          <div class="d-flex flex-wrap pa-1" style="gap:3px 8px">
            <action-chip
              v-for="(v, key) in value"
              :key="`${item.id}-${key}s`"
              :action="key"
              :count="v"
              class="pl-2"
              small
            />
          </div>
        </template>

        <template #item.status="props">
          <import-history-status-cell v-bind="props" />
        </template>
      </v-data-table>
    </v-card>

    <bulk-action-modals v-bind="bulkActionsMenuProps" />
  </div>
</template>

<script>
import pluralize from "pluralize";
import onWindowFocus from "@cp/mixins/onWindowFocus";
import { toMDYTime } from "@cp/utils/dateUtils";

import { importHistory } from "@/store/modules/importHistory";

const subTableHeaders = [
  {
    text: "Sheet Name",
    value: "upload_details.sheet_name",
    width: "100%",
    sortable: false,
    cellClass: "text-end",
  },
  {
    text: "Adds",
    value: "audit_summary.create",
    width: 30,
    sortable: false,
  },
  {
    text: "Changes",
    value: "audit_summary.update",
    width: 30,
    sortable: false,
  },
  {
    text: "Archives",
    value: "audit_summary.archived",
    width: 30,
    sortable: false,
  },
  {
    text: "Restores",
    value: "audit_summary.restored",
    width: 30,
    sortable: false,
  },
  {
    text: "Errors",
    value: "audit_summary.errors",
    width: 30,
    sortable: false,
  },
];

export default {
  mixins: [
    importHistory.table.mixin,
    onWindowFocus(function() {
      this.fetchItems();
    }),
  ],
  data() {
    return {
      subTableHeaders,
    };
  },
  methods: {
    toMDYTime,
    pluralize,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.refreshTable());
  },
};
</script>

<style lang="scss" scoped>
.SubTable {
  border-radius: 0;
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    pointer-events: none;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  &::v-deep {
    td {
      font-size: 0.875rem;
    }
    thead tr > th:first-child {
      text-align: end !important;
    }
  }
}
</style>
