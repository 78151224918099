<template>
  <v-tooltip right v-if="deficiencies.length > 0">
    <template #activator="{ on, attrs }">
      <v-icon
        small
        class="icon icon-warning"
        color="warning"
        v-bind="attrs"
        v-on="on"
      >
        mdi-alert
      </v-icon>
    </template>
    <h4 v-for="(label, i) in deficiencies" :key="i">
      {{ label }}
    </h4>
  </v-tooltip>
</template>

<script>
export default {
  name: "DeficiencyAlertCell",
  props: ["item"],
  computed: {
    deficiencies() {
      return Object.values(this.item.deficiencies || {}).map(
        ({ label }) => label
      );
    },
  },
};
</script>
