import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"text-h6 text--secondary mb-3"},[_vm._v("Update Review Links")]),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_vm._l((_vm.sites),function(ref){
var name = ref.name;
var url = ref.url;
var id = ref.id;
return [_c(VTextField,{key:("site-" + id),attrs:{"value":url,"label":name},on:{"input":function (v) { return _vm.set(id, v); }}})]})],2)],1),_c(VBtn,{attrs:{"disabled":_vm.disableButton,"color":"primary","loading":_vm.submitting},on:{"click":_vm.submit}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save-outline")]),_vm._v(" Update Review Links ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }