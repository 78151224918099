<template>
  <div>
    <h6 class="text-h6 text--secondary mb-3">Update Review Links</h6>
    <v-row>
      <v-col cols="6">
        <template v-for="{ name, url, id } in sites">
          <v-text-field
            :value="url"
            :key="`site-${id}`"
            :label="name"
            @input="v => set(id, v)"
          />
        </template>
      </v-col>
    </v-row>
    <v-btn
      @click="submit"
      :disabled="disableButton"
      color="primary"
      :loading="submitting"
    >
      <v-icon left>mdi-content-save-outline</v-icon>
      Update Review Links
    </v-btn>
  </div>
</template>

<script>
import intersect from "vuetify/lib/directives/intersect";

import { get, isEmpty } from "@cp/utils/objectUtils";

export default {
  directives: { intersect },
  props: {
    modelModule: { type: Object, required: true },
  },
  data() {
    return {
      formData: {},
      submitting: false,
    };
  },
  computed: {
    item() {
      return get(this.$store.state, this.modelModule.form.p.s.stateKey);
    },
    sites() {
      return get(this.item, "review_links.review_links", []);
    },
    disableButton() {
      return !Object.keys(this.formData).length;
    },
  },
  methods: {
    isEmpty,
    set(id, value) {
      this.$set(this.formData, id, value);
    },
    async submit() {
      this.submitting = true;
      const config = {
        url: "/:id/review_links",
        method: "POST",
        id: this.item.id,
      };
      const data = {
        review_links: Object.entries(this.formData).map(
          ([review_site_id, url]) => ({
            review_site_id,
            url,
          })
        ),
      };
      try {
        const response = await this.modelModule.form.request(config, data);
        this.submitting = false;
        Object.keys(this.formData).forEach(key => {
          this.$set(this.formData, key, undefined);
        });
        this.$CpEvent.$emit("snackAlert", {
          message: "Review Links Updated!",
          color: "success",
        });
      } catch (err) {
        console.log(err);
        this.$CpEvent.$emit("snackAlert", {
          message: "Sorry, Something went wrong.",
          color: "error",
        });
      }
    },
  },
  mounted() {
    const id = this.$router.currentRoute.params.id;
    this.$store.dispatch(this.modelModule.audit.p.a.fetch, { id });
  },
};
</script>
