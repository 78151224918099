<template>
  <div>
    <date-picker v-bind="dateProps" @input="input" />

    <div class="d-flex justify-end mt-6">
      <div v-if="success" class="d-flex align-center">
        <CpSuccessIcon class="mr-4" xLarge />
        {{ pluralize("Employee", this.selectedIds.length, true) }} Archived!
      </div>
      <template v-else>
        <v-btn @click="action.modalConf.close" class="mr-4">Cancel</v-btn>
        <v-btn @click="confirm" color="primary" :loading="submitting">
          Archive {{ pluralize("Employee", this.selectedIds.length, true) }}
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import pluralize from "pluralize";

import { get } from "@cp/utils/objectUtils";
import { toYMD } from "@cp/utils/dateUtils";

import { employee } from "@/store/modules/employee";
import DatePicker from "../forms/DatePicker.vue";

const SUB = employee.table.subModuleRefs.bulkArchive;

export default {
  components: { DatePicker },
  name: "BulkSetEmployeesManager",
  props: {
    selected: { type: Array, required: true },
    action: { type: Object, required: true },
  },
  computed: {
    ...mapState(employee.search.mp, {
      items: employee.search.keys.stateKey,
      loading: employee.search.keys.loading,
    }),
    errors() {
      return this.$store.getters[SUB.p.g.errors];
    },
    formConf() {
      return {
        submitting: this.submitting,
        success: this.success,
        unhandledErrors: this.errors.unhandledErrors,
      };
    },
    selectedIds() {
      return this.selected.map(x => x.id);
    },
    dateProps() {
      return {
        defaultValue: toYMD(),
        label: "Archive Date",
      };
    },
    managerIds() {
      return get(this.$store.state, SUB.p.s.formData + ".manager_ids");
    },
    success() {
      return get(this.$store.state, SUB.p.s.success);
    },
    submitting() {
      return get(this.$store.state, SUB.p.s.submitting);
    },
  },
  methods: {
    pluralize,
    ...mapActions(employee.search.mp, {
      search: employee.search.keys.search,
    }),
    input(value) {
      this.$store.commit(SUB.p.m.input, { last_archived_at: value });
      this.$store.commit(SUB.p.m.setTouched, "last_archived_at");
    },
    async confirm() {
      const data = {
        ids: this.selectedIds,
        last_archived_at: get(
          this.$store.state,
          SUB.p.s.formData + ".last_archived_at"
        ),
      };
      this.$store.dispatch(SUB.p.a.confirm, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-label {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: $gray-4;
}

.v-card__actions {
  padding: 0 24px 20px !important;
}
</style>
