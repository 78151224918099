import { VCard } from 'vuetify/lib/components/VCard';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',{attrs:{"hideBackButton":_vm.hideBackButton},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v("Total: "+_vm._s(_vm.meta.pagination.records))]},proxy:true},{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCard,[_c('Table',_vm._g(_vm._b({class:{ 'clickable-rows': _vm.clickableRows },on:{"click:row":_vm.clickOnRow},scopedSlots:_vm._u([{key:"item.editor",fn:function(ref){
var value = ref.value;
return [_c('span',[(value.editor_first_name || value.editor_last_name)?_c('span',[_vm._v(_vm._s(value.editor_first_name)+" "+_vm._s(value.editor_last_name))]):_c('span',[_vm._v(_vm._s(value.editor_note))])])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.toMDYTime(value))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.auditable_type === 'EtlMap')?_c('action-chip',{attrs:{"action":"etl","small":""}}):(item.name)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var value = ref.value;
return [_c('action-chip',{staticClass:"pl-2",attrs:{"action":value,"small":""}})]}},{key:"item.summary",fn:function(ref){
var item = ref.item;
return [(item.action === 'update' && _vm.nonEmpty(item.changes))?_c('div',_vm._l((item.changes),function(ref,key){
var p = ref[0];
var n = ref[1];
return _c('div',{key:key,staticClass:"text-truncate"},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(key)+":")]),_c('span',{staticClass:"text-caption"},[(p)?_c('span',[_vm._v(_vm._s(p))]):_c('span',{staticClass:"font-italic"},[_vm._v("empty")]),_vm._v(" → "),(n)?_c('span',[_vm._v(_vm._s(n))]):_c('span',{staticClass:"font-italic"},[_vm._v("empty")])])])}),0):_vm._e(),(item.action === 'create')?_c('div',[(item.auditable_type === 'User')?_c('div',[_vm._v(" New user: "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.name))]),(item.position)?_c('div',{staticClass:"text-truncate"},[_vm._v(" Position: "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.position))])]):_vm._e(),(item.location)?_c('div',{staticClass:"text-truncate"},[_vm._v(" Location: "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.location))])]):_vm._e()]):(item.auditable_type === 'EtlMap')?_c('div',[_vm._v(" New transform for "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.changes.extract_id))])]):_vm._e()]):_vm._e()]}}]),model:{value:(_vm.tableSelected),callback:function ($$v) {_vm.tableSelected=$$v},expression:"tableSelected"}},'Table',_vm.tableProps,false),_vm.tableListeners))],1),_c('bulk-action-modals',_vm._b({},'bulk-action-modals',_vm.bulkActionsMenuProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }