<template>
  <div>
    <cp-loading :loading="countsLoading">
      <v-row>
        <v-col cols="3">
          <cp-h1>
            Data triage
            <template v-if="hasIssues" #subtitle>
              We've found {{ pluralize("issue", hasIssues, true) }}
            </template>
          </cp-h1>
          <v-card>
            <v-list dense>
              <template v-for="{ model, label, counts } in models">
                <v-subheader :key="`${model}-header`">{{ label }}</v-subheader>
                <v-list-item
                  v-for="(typeCount, type) in counts"
                  :key="`${model}__${type}`"
                  :to="{ name: 'DataTriage', query: { model, type } }"
                  exact
                >
                  <v-list-item-content class="text-subtitle">
                    <v-list-item-title>
                      {{ typeCount.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="warning--text">
                      <span v-if="typeCount.count > 0">{{
                        typeCount.count
                      }}</span>
                      <span v-else class="success--text">
                        No issues
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="9">
          <cp-h1>
            {{ modelLabelMap[model] }}

            <template #subtitle>
              <span v-if="selected.length">
                <bulk-action-modals v-bind="bulkActionsMenuProps" />
                <bulk-actions-menu v-bind="bulkActionsMenuProps" />
              </span>
              <span v-else>Total: {{ meta.pagination.records }}</span>
            </template>

            <template v-if="selected.length" #rightOfTitle> </template>
          </cp-h1>
          <Table
            v-bind="tableProps"
            v-on="tableListeners"
            v-model="tableSelected"
            :headers="modelClass.table.headers"
            @click:row="navigateToItem"
            class="clickable-rows"
            show-select
          />
        </v-col>
      </v-row>
    </cp-loading>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import pluralize from "pluralize";

import { onCpEvent } from "@cp/mixins";
import { capitalize } from "@cp/utils/stringUtils";
import { get, pick } from "@cp/utils/objectUtils";

import { table } from "@/store/modules/issues";
import { employee } from "@/store/modules/employee";
import { location } from "@/store/modules/location";
import { position } from "@/store/modules/position";
import { region } from "@/store/modules/region";

const modelClasses = {
  user: employee,
  location,
  position,
  geographical_group: region,
};

const modelLabelMap = {
  user: "Employees",
  position: "Positions",
  location: "Locations",
  geographical_group: "Regions",
};

export default {
  name: "DataTriage",
  mixins: [
    table.mixin,
    onCpEvent("bulkActionSuccess", function() {
      this.update();
      this.fetchItems();
      this.tableSelected = [];
    }),
  ],
  data() {
    const model = this.$route.query.model || "user";
    const type = this.$route.query.type || "";
    return { modelClasses, modelLabelMap, model, type };
  },
  computed: {
    ...mapState("issues", ["counts", "countsLoading"]),
    models() {
      return Object.entries(this.counts).map(([model, counts]) => {
        const label = modelLabelMap[model];
        return { model, label, counts };
      });
    },
    modelClass() {
      return this.modelClasses[this.model];
    },
    bulkActionsMenuProps() {
      return {
        bulkActions: this.$store.getters[this.modelClass.table.p.g.bulkActions],
        selected: this.tableSelected,
        noun: this.modelClass.noun,
      };
    },
    hasIssues() {
      if (this.countsLoading) return false;
      return Object.values(this.counts).reduce(
        (x, modelIssues) =>
          x +
          Object.values(modelIssues).reduce(
            (y, issueCounts) => y + issueCounts.count,
            0
          ),
        0
      );
    },
  },
  methods: {
    get,
    pluralize,
    capitalize,
    ...mapActions("issues", { update: "fetchCounts" }),
    ...mapActions("issues", ["setupTable", "fetchItems"]),
    menuItemIsActive({ model, type }) {
      return this.model === model && this.type === type;
    },
    changeTable({ model, type }) {
      this.model = model;
      this.type = type;
      this.tableSelected = [];
      this.setupTable({ model, type });
      this.$CpEvent.$emit("updateRoute", { query: { model, type } });
    },
    navigateToItem({ id }) {
      const name = `${this.modelClass.modulePath[0]}Detail`;
      const backRoute = pick(this.$route, ["name", "query"]);
      this.$router.push({
        name,
        params: { id },
        query: { backRoute },
      });
    },
  },

  async mounted() {
    await this.update();
    if (
      !this.$route.query.type ||
      !this.counts[this.model].hasOwnProperty(this.$route.query.type)
    ) {
      this.type = Object.keys(this.counts[this.model])[0];
    }

    if (
      this.$route.query.model !== this.model ||
      this.$route.query.type !== this.type
    ) {
      this.$router.push({
        name: "DataTriage",
        query: {
          model: this.model,
          type: this.type,
        },
      });
    } else {
      this.changeTable({
        model: this.model,
        type: this.type,
      });
    }

    this.$CpEvent.$on("issuesUpdated", this.update);
  },

  watch: {
    "$route.query": {
      handler(value) {
        this.tableSelected = [];
        this.changeTable(pick(value, ["model", "type"]));
      },
      deep: true,
    },
  },

  beforeDestroy() {
    this.tableSelected = [];
    this.$CpEvent.$off("issuesUpdated");
  },
};
</script>

<style lang="scss" scoped>
.v-list-item:hover {
  &:hover,
  *:hover {
    text-decoration: none;
  }
}
.v-list-item--active:before {
  background: #0070a8;
}
</style>
